@font-face {
  font-family: 'Copperplate';
  src: url('OPTICopperplate.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Copperplate';
  src: url('OPTICopperplate-Light.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Copperplate';
  src: url('OPTICopperplate-Heavy.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}
