@font-face {
  font-family: 'Mariners';
  src: url('CondensedBold.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Mariners';
  src: url('CondensedRegular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
