@font-face {
  font-family: 'SFPRO';
  src: url('SF-Pro-Display-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFPRO';
  src: url('SF-Pro-Display-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SFPRO';
  src: url('SF-Pro-Display-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SSFPRO';
  src: url('SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: 650;
  font-style: normal;
}

@font-face {
  font-family: 'SFPRO';
  src: url('SF-Pro-Display-Black.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
